import axios from "axios";
import config from "@config";

const API = `${config.api}/admin/authors`;

// Возвращает всех авторов
export const getAll = (params) =>
  axios
    .get(API, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Возвращает автора
export const getOne = (params) =>
  axios
    .get(`${API}/id/${params.id}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Обновляет автора
export const update = (data) =>
  axios
    .post(`${API}/update/id/${data.id}`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Создает автора
export const create = (data = null) =>
  axios
    .post(API, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));
