import { Layout, Menu, Dropdown, Avatar, PageHeader } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as Icon from "@ant-design/icons";
import { Loading } from "@components";
import { useState } from "react";

const { Header, Content, Footer, Sider } = Layout;

const usermenu = (
  <Menu>
    <Menu.Item danger>
      <Link to="/logout">Logout</Link>
    </Menu.Item>
  </Menu>
);

export default function AdminLayout(props) {
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const { title, subtitle, children, loading, extra } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const selectedKeys = (alias) => {
    if (location.pathname === "/") {
      return ["home"];
    }

    const loc = location.pathname.substr(1).split("/");
    return [loc[0]];
  };

  const onCollapse = (collapsed) => {
    setIsNavCollapsed(collapsed);
  };

  return (
    <Layout {...props} style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={isNavCollapsed} onCollapse={onCollapse}>
        <div className="logo-top">
          <img
            className="logo-full"
            alt="Logo"
            src={
              isNavCollapsed ? "/img/logo-compact.svg" : "/img/logo-light.svg"
            }
          />
        </div>
        <Menu
          theme="dark"
          selectedKeys={selectedKeys()}
          defaultSelectedKeys={["home"]}
          mode="inline"
        >
          <Menu.Item key="home" icon={<Icon.HomeOutlined />}>
            <Link to="/">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="articles" icon={<Icon.GlobalOutlined />}>
            <Link to="/articles">Articles</Link>
          </Menu.Item>
          <Menu.Item key="stories" icon={<Icon.MobileOutlined />}>
            <Link to="/stories">Stories</Link>
          </Menu.Item>
          <Menu.Item key="categories" icon={<Icon.ContainerOutlined />}>
            <Link to="/categories">Categories</Link>
          </Menu.Item>
          <Menu.Item key="projects" icon={<Icon.ShopOutlined />}>
            <Link to="/projects">Projects</Link>
          </Menu.Item>
          <Menu.Item key="profiles" icon={<Icon.IdcardOutlined />}>
            <Link to="/profiles">Profiles</Link>
          </Menu.Item>
          <Menu.Item key="tags" icon={<Icon.TagsOutlined />}>
            <Link to="/tags">Tags</Link>
          </Menu.Item>
          <Menu.Item key="authors" icon={<Icon.IdcardOutlined />}>
            <Link to="/authors">Authors</Link>
          </Menu.Item>
          <Menu.Item disabled key="sources" icon={<Icon.SoundOutlined />}>
            <Link to="/sources">Media Sources</Link>
          </Menu.Item>
          <Menu.Divider style={{ borderColor: "rgba(255,255,255, .1)" }} />
          <Menu.Item danger key="logout" icon={<Icon.LogoutOutlined />}>
            <Link to="/logout">Logout</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background site-header"
          style={{ padding: 0 }}
        >
          <Dropdown overlay={usermenu}>
            <Avatar
              style={{ cursor: "pointer" }}
              icon={<Icon.UserOutlined />}
            />
          </Dropdown>
        </Header>
        <Content style={{ margin: "16px" }}>
          <div
            className={`site-layout-background ${props.className}`}
            style={{ padding: 24, minHeight: 360 }}
          >
            <PageHeader
              className="site-page-header"
              // onBack={() => window.history.go(-1)}
              onBack={() => navigate(-1, { replace: false })}
              title={title}
              subTitle={subtitle}
              extra={extra}
            />
            {loading ? <Loading /> : children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Musan Digital © {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
}
