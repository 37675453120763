import axios from "axios";
import config from "@config";

const API = `${config.api}/admin/tags`;

// Возвращает все теги
export const getAll = (params) =>
  axios
    .get(API, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Возвращает тег
export const getOne = (params) =>
  axios
    .get(`${API}/id/${params.id}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Поиск тегов
export const searchAll = (params) =>
  axios
    .get(`${API}/name/${params.search}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Обновляет тег
export const update = (data) =>
  axios
    .post(`${API}/update/id/${data.id}`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Создает тег
export const create = (data = null) =>
  axios
    .post(API, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

