import adminroutes from "./admin";
import editorroutes from "./editor";
import { Navigate } from "react-router-dom";
import { Loading } from "@components";
import { useRoutes } from "react-router-dom";

export default function RoutesHandler(props) {
  const { user, loading } = props;
  const admin = useRoutes(adminroutes(props));
  const editor = useRoutes(editorroutes(props));

  console.log("user", user);

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (user?.role === "editor") {
    return editor;
  }

  return admin;
}
