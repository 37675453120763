import { useState } from "react";
import { Form, Input, Button, Upload, message } from "antd";
import config from "@config";
import * as Icons from "@ant-design/icons";
import * as API from "@api";
import * as R from "ramda";

const { Dragger } = Upload;

export default function Slides(params) {
  const [loading, setLoading] = useState(false);

  const addSlide = (data) => {
    return params.setStory({
      ...params.story,
      slides: params.story?.slides ? [...params.story?.slides, data] : [data],
    });
  };

  const deleteSlide = (data) => {
    return params.setStory({
      ...params.story,
      slides: R.without([data], params.story?.slides),
    });
  };

  console.log("params", params);

  return [
    params.story?.slides?.map((slide, key) => (
      <div key={key} className="col-span-12 lg:col-span-4">
        <div className="stories-slide-container">
          {slide.type === "image" && (
            <img
              className="slide-img"
              alt="Stories Slide"
              src={`${config.s3}/${slide.data}`}
            />
          )}
          {slide.type === "video" && (
            <video
              className="slide-video"
              alt="Stories Slide"
              src={`${config.s3}/${slide.data}`}
              autoPlay
              muted
              loop
              controls
            />
          )}
          <Button
            className="slide-delete-btn"
            danger
            type="primary"
            shape="circle"
            icon={<Icons.DeleteOutlined />}
            onClick={() => deleteSlide(slide)}
            size={20}
          />
        </div>
      </div>
    )),
    <div className="col-span-12 lg:col-span-4">
      <div className="stories-slide-container">
        <Form.Item
          tooltip="Upload an image to be used as the article preview image"
          name="image_upload"
          className="slide-uploader"
        >
          <Dragger
            multiple={false}
            name="slide_upload"
            accept="image/*,video/mp4"
            className="w-full h-full"
            disabled={loading}
            showUploadList={false}
            customRequest={async ({ onSuccess, onError, file }) => {
              try {
                setLoading(true);
                const type = R.includes("video/mp4", file.type)
                  ? "video"
                  : R.includes("image/")
                  ? "image"
                  : null;

                if (!type) {
                  return onError("Invalid file type.");
                }

                const _res =
                  type === "video"
                    ? await API.Static.uploadVideo(file)
                    : await API.Static.uploadImage(file);

                if (!_res.file) {
                  throw new Error();
                }

                addSlide({
                  type: type,
                  data: _res.file.path,
                  duration: type === "image" ? 5 : 0,
                });
                onSuccess();
                setLoading(false);
              } catch (error) {
                setLoading(false);
                onError();
                return message.error("Error uploading image", 2);
              }
            }}
          >
            <Button
              type="link"
              icon={
                loading ? <Icons.LoadingOutlined /> : <Icons.UploadOutlined />
              }
            >
              Upload Media
            </Button>
          </Dragger>
        </Form.Item>
      </div>
    </div>,
  ];

  return (
    <Form.List name="slides">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => {
            console.log("field", field);

            return (
              <div className="col-span-12 lg:col-span-4">
                <div className="stories-slide-container">Test</div>
              </div>
            );

            return (
              <Form.Item
                label={index === 0 ? "Passengers" : ""}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message:
                        "Please input passenger's name or delete this field.",
                    },
                  ]}
                  noStyle
                >
                  <Input
                    placeholder="passenger name"
                    style={{ width: "60%" }}
                  />
                </Form.Item>
                {/* {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null} */}
              </Form.Item>
            );
          })}
        </>
      )}
    </Form.List>
  );

  return [
    <div className="col-span-12 lg:col-span-4">
      <div className="stories-slide-container">Test</div>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <div className="stories-slide-container">Test</div>
    </div>,
    <div className="col-span-12 lg:col-span-4">
      <div className="stories-slide-container">Test</div>
    </div>,
  ];
}
