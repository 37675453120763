import { Table, Button, Tag } from "antd";
import { Link } from "react-router-dom";
import config from "@config";
import moment from "moment";

export default function DataTable(params) {
  return (
    <Table
      onChange={params.onChange}
      pagination={params.pagination}
      dataSource={params.dataSource}
      loading={params.loading}
      columns={[
        {
          dataIndex: "image",
          key: "image",
          align: "center",
          width: 120,
          render: (data) => (
            <img
              src={data ? `${config.s3}/${data}` : "/img/no-image.svg"}
              className="block w-full"
              width={100}
              height={100}
              alt={data}
            />
          ),
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          align: "left",
          width: 400,
          render: (data, all) => (
            <div>
              <h4 className="block m-0 text-base">{data}</h4>
              <p className="block m-0 mb-2 text-sm text-slate-500">
                {all?.position || "No position info"}
              </p>
            </div>
          ),
        },
        {
          title: "Status",
          dataIndex: "published",
          key: "published",
          align: "center",
          render: (data) =>
            data ? (
              <Tag color="success">Published</Tag>
            ) : (
              <Tag>Not Published</Tag>
            ),
        },
        {
          title: "Last Update",
          dataIndex: "updated_at",
          key: "updated_at",
          align: "center",
          render: (data) => moment(data).format("DD MMM YYYY — HH:mm"),
        },
        {
          dataIndex: "actions",
          key: "actions",
          align: "right",
          render: (_, all) => (
            <Link to={`/profiles/edit/${all.id}`}>
              <Button type="primary">Edit Profile</Button>
            </Link>
          ),
        },
      ]}
    />
  );
}
