import { useRef, useState } from "react";
import { Form, Input, Button, message, Modal } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import * as API from "@api";
import ReCAPTCHA from "react-google-recaptcha";
import config from "@config";

export default function LoginForm({ isRegister = false }) {
  const recaptcha = useRef(null);
  const [resetting, setResetting] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleSignin(data) {
    setLoading(true);
    const { email = null, password = null } = data;
    const recaptchaValue = recaptcha?.current?.getValue();

    if (!email || !password) {
      setLoading(false);
      return message.error("Please fill all required fields", 3);
    }

    if (!recaptchaValue) {
      setLoading(false);
      return message.error("Please verify you are not a robot", 3);
    }

    const _res = await API.Auth.login({
      email,
      password,
      recaptcha: recaptchaValue,
    });

    setLoading(false);

    if (_res.error) {
      recaptcha.current.reset();
      return message.error(
        _res.data || "Something went wrong, please try again",
        3
      );
    }

    setLoading(false);
    window.location.href = "/";
  }

  async function handleRegister(data) {
    const register = async () => {
      setLoading(true);
      const {
        email = null,
        password = null,
        first_name = null,
        last_name = null,
      } = data;
      const recaptchaValue = recaptcha?.current?.getValue();

      if (!email || !password || !first_name || !last_name) {
        setLoading(false);
        return message.error("Please fill all required fields", 3);
      }

      if (!recaptchaValue) {
        setLoading(false);
        return message.error("Please verify you are not a robot", 3);
      }

      const _res = await API.Auth.register({
        first_name,
        last_name,
        email,
        password,
        recaptcha: recaptchaValue,
      });

      setLoading(false);

      if (_res.error) {
        recaptcha.current.reset();
        return message.error(
          _res.data || "Something went wrong, please try again",
          3
        );
      }

      setLoading(false);
      window.location.href = "/";
    };

    Modal.confirm({
      title: "Registration as an Editor",
      content: (
        <p>
          Please read and accept our{" "}
          <a href={`${config.frontend}/terms`} target="_blank">
            Terms &amp; Conditions
          </a>{" "}
          before registering.
        </p>
      ),
      okText: "Accept & Register",
      onOk: register,
    });
  }

  function switchMode(e) {
    e.preventDefault();
    setResetting(!resetting);
  }

  return (
    <Form
      className="login-form"
      name="login"
      initialValues={{ remember: true }}
      onFinish={isRegister ? handleRegister : handleSignin}
      size="large"
    >
      <h5 className="mt-2 mb-6">
        {resetting
          ? "Reset Password"
          : isRegister
          ? "Register"
          : "Sign In to Editor's Hub"}
      </h5>
      <Form.Item
        className="mb-3"
        name="first_name"
        hidden={!isRegister}
        rules={[
          {
            required: isRegister,
            message: "Please enter your first name",
          },
        ]}
      >
        <Input
          disabled={loading}
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="First name"
        />
      </Form.Item>
      <Form.Item
        className="mb-3"
        name="last_name"
        hidden={!isRegister}
        rules={[
          {
            required: isRegister,
            message: "Please enter your last name",
          },
        ]}
      >
        <Input
          disabled={loading}
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Last name"
        />
      </Form.Item>
      <Form.Item
        className="mb-3"
        name="email"
        rules={[
          {
            required: true,
            message: "Please enter your email",
          },
        ]}
      >
        <Input
          disabled={loading}
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      {!resetting && (
        <Form.Item
          className="mb-3"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            disabled={loading}
          />
        </Form.Item>
      )}
      <div className="recaptcha-layout mb-3">
        <ReCAPTCHA
          key="grecaptcha"
          sitekey={config.recaptcha_key}
          ref={recaptcha}
        />
      </div>
      <Form.Item className="my-2">
        <Button
          type="primary"
          htmlType="submit"
          className="admin-login-form-button"
          loading={loading}
          block
        >
          {resetting ? "Reset Password" : isRegister ? "Register" : "Sign In"}
        </Button>
      </Form.Item>
      <div className="flex justify-center items-center">
        <Button
          hidden={isRegister}
          onClick={switchMode}
          type="link"
          className="px-0"
        >
          {resetting ? "Back to Sign" : "Forgot Password"}
        </Button>
        <div hidden={!isRegister}>
          Already have an account?{" "}
          <Button href="/login" type="link" className="px-0">
            Sign In
          </Button>
        </div>
      </div>
    </Form>
  );
}
