import React, { useEffect, useState, useMemo, useCallback } from 'react';

import useDebounce from './useDebounce';

const BASE_API = process.env.REACT_APP_API_ENDPOINT;
const BASE_IMG = process.env.REACT_APP_S3_ENDPOINT;

const Input = ({ data, onDataChange }) => {
  const [selectedItems, setSelectedItems] = useState(data.items.length > 0 ? data : { items: [] } )
  const [input, setInput] = useState('');

  const debouncedInput = useDebounce(input, 1000);

  const isInSelected = useMemo(() => {
    return selectedItems.items.find(item => String(item.id) === debouncedInput)
  }, [debouncedInput, selectedItems])

  const isMaxLengthReached = selectedItems.items.length === 3

  const handleClickOnDelete = useCallback((id) => () => {
    const filteredList = selectedItems.items.filter(item => item.id !== id);
    setSelectedItems(prev => ({...prev, items: filteredList}))
  }, [selectedItems])
  
  useEffect(() => {
    const fetchArticle = async () => {
      const response = await fetch(`${BASE_API}/articles/id/${debouncedInput}`);
      const jsonData = await response.json();
  
      if (jsonData.data) {
        setSelectedItems(prev => ({ items: [...prev.items, jsonData.data.article ] }))
        setInput('')
      }
    }

    // Если введеный ID отсутствует в списке и не достигнуто 3 материалов, то сделать запрос и поместить в список
    debouncedInput && !isInSelected && !isMaxLengthReached && fetchArticle();
  }, [debouncedInput, isInSelected, isMaxLengthReached]);

  useEffect(() => {
    // синхронизация с плагином editor js 
    onDataChange(selectedItems)
  }, [onDataChange, selectedItems]);

  return (
    <div className="inner">
      <input
        name="search"
        value={input}
        type="text"
        placeholder="Enter the ID of the article"
        onChange={e => setInput(e.target.value)}
      />

      <div className="inner-list">
        {selectedItems.items.map(e => (
          <div key={e.id} className="inner-list--item">
            <button onClick={handleClickOnDelete(e.id)}>
              <svg width={12} height={12}>
                <use xmlns='http://www.w3.org/1999/xlink' xlinkHref='#cross'></use>
              </svg>
            </button>
            <img src={`${BASE_IMG}/${e.image}`} width={210} height={150} alt={e.title} />
            <h5>{e.title}</h5>
          </div>
        ))}
      </div>

    </div>
  )
}

export default Input