import { createRef } from "react";
import { Form, Input, Select } from "antd";
import SidebarActions from "./sidebar/actions";

export default function EditForm(params) {
  const form = createRef();

  return (
    <Form
      name="category"
      ref={form}
      initialValues={params.category}
      onFinish={params.handleSave}
      autoComplete="off"
      layout="vertical"
      className="article-edit-form"
    >
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-8">
          <Form.Item
            tooltip="Enter category name"
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please enter category name" }]}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            tooltip="Enter category long name"
            label="Long name"
            name="long_name"
            rules={[
              { required: true, message: "Please enter category long name" },
            ]}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            tooltip="Enter category description"
            label="Description"
            name="description"
          >
            <Input.TextArea rows={4} className="w-full" />
          </Form.Item>
          <Form.Item
            label="Parent category"
            name="parent_id"
            tooltip="If this category is a sub-category, select the parent category here"
          >
            <Select
              className="w-full"
              placeholder="Select parent category"
              allowClear
              optionFilterProp="children"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {params.categories.map((data) => (
                <Select.Option
                  disabled={data.id === params.category.id}
                  key={data.id}
                  value={data.id}
                >
                  {data.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col-span-12 lg:col-span-4 h-full">
          <SidebarActions {...params} form={form} />
        </div>
      </div>
    </Form>
  );
}
