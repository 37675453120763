import { createRef } from "react";
import { Form, Input, Divider, Select } from "antd";
import SidebarActions from "./sidebar/actions";
import Table from "./table";

export default function EditForm(params) {
  const form = createRef();

  return (
    <Form
      name="author"
      ref={form}
      initialValues={params.author}
      onFinish={params.handleSave}
      autoComplete="off"
      layout="vertical"
      className="article-edit-form"
    >
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-8">
          <Form.Item
            tooltip="Enter author name"
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please enter author name" }]}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            tooltip="Internal user under this author"
            label="Internal User"
            name="user_id"
          >
            <Select
              className="w-full"
              placeholder="Select internal user"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {params.users.map((data) => (
                <Select.Option
                  disabled={data.id === params.author.user_id}
                  key={data.id}
                  value={data.id}
                >
                  {data.first_name} {data.last_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Divider>Tag Articles</Divider>
          <Table author={params.author} />
        </div>
        <div className="col-span-12 lg:col-span-4 h-full">
          <SidebarActions {...params} form={form} />
        </div>
      </div>
    </Form>
  );
}
