import { createRef, useState, useEffect } from "react";
import { CKEditor } from "@components";
import { Form, Input, Divider, DatePicker, Select } from "antd";
import SidebarActions from "./sidebar/actions";
import Images from "./images";
import * as API from "@api";

export default function EditForm(params) {
  const [categories, setCategories] = useState([]);
  const form = createRef();

  useEffect(() => {
    API.ProfileCategories.getAll().then((res) => {
      console.log("res", res);
      if (res.error) return;
      setCategories(res?.data?.rows);
    });
  }, []);

  if (!categories.length) {
    return null;
  }

  return (
    <Form
      name="profile"
      ref={form}
      initialValues={params.profile}
      onFinish={params.handleSave}
      autoComplete="off"
      layout="vertical"
      className="article-edit-form"
    >
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-8">
          <Form.Item
            tooltip="Select profile category"
            label="Category"
            name="category_id"
            rules={[
              { required: true, message: "Please select profile category" },
            ]}
          >
            <Select className="w-full">
              {categories?.map((cat, i) => (
                <Select.Option key={i} value={cat.id}>
                  {cat.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            tooltip="Enter profile name"
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please enter profile name" }]}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            tooltip="Slug is used for profile url generation (auto generated)"
            label="Slug (URL)"
            name="slug"
            rules={[
              {
                required: true,
                message: "Please enter profile name for slug generation",
              },
            ]}
          >
            <Input disabled className="w-full" />
          </Form.Item>
          <Form.Item
            tooltip="Enter profile date of birth"
            label="Birth Date"
            name="birthdate"
          >
            <DatePicker format="DD MMM YYYY" className="w-full" />
          </Form.Item>
          <Form.Item
            tooltip="Enter profile position"
            label="Position"
            name="position"
          >
            <Input.TextArea rows={2} className="w-full" />
          </Form.Item>
          <Form.Item
            tooltip="Enter profile work place"
            label="Work place"
            name="job"
          >
            <Input.TextArea rows={2} className="w-full" />
          </Form.Item>
          <Form.Item
            tooltip="Enter profile education"
            label="Education"
            name="education"
          >
            <Input.TextArea rows={4} className="w-full" />
          </Form.Item>
          <Divider />
          <Images {...params} />
          <Divider />
          <h6 className="text-lg">Profile Bio</h6>
          <CKEditor
            className="w-full"
            value={params.bio}
            onChange={params.setBio}
          />
        </div>
        <div className="col-span-12 lg:col-span-4 h-full">
          <SidebarActions {...params} form={form} />
        </div>
      </div>
    </Form>
  );
}
