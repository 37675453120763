import { Table, Button, Tag } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";

export default function DataTable(params) {
  return (
    <Table
      onChange={params.onChange}
      pagination={params.pagination}
      dataSource={params.dataSource}
      loading={params.loading}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          align: "left",
          width: 400,
          render: (data, all) => (
            <div>
              <h4 className="block m-0 text-base">{data}</h4>
              <p className="block m-0 mb-2 text-sm text-slate-500">
                {all.description}
              </p>
            </div>
          ),
        },
        {
          title: "Status",
          dataIndex: "published",
          key: "published",
          align: "center",
          render: (data) =>
            data ? (
              <Tag color="success">Published</Tag>
            ) : (
              <Tag>Not Published</Tag>
            ),
        },
        {
          title: "Published Articles",
          dataIndex: "articles",
          key: "articles",
          align: "center",
          render: (data) => <Tag>{data?.length || 0}</Tag>,
        },
        {
          title: "Last Update",
          dataIndex: "updated_at",
          key: "updated_at",
          align: "center",
          render: (data) => moment(data).format("DD MMM YYYY — HH:mm"),
        },
        {
          dataIndex: "actions",
          key: "actions",
          align: "right",
          render: (_, all) => (
            <Link to={`/projects/edit/${all.id}`}>
              <Button type="primary">Edit Project</Button>
            </Link>
          ),
        },
      ]}
    />
  );
}
