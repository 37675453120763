import { useEffect, useState } from "react";
import { Form, Input, Divider } from "antd";
import { CKEditor } from "@components";
import SidebarActions from "./sidebar/actions";
import Table from "./table";

export default function EditForm(params) {
  // const [form] = Form.useForm();
  const form = params?.form;
  const [description, setDescription] = useState(null);

  useEffect(() => {
    if (form) {
      setDescription(form.getFieldValue("meta")?.description);
    }
  }, [form]);

  if (!form) {
    return null;
  }

  return (
    <Form
      name="tag"
      form={form}
      initialValues={params.tag}
      onFinish={params.handleSave}
      autoComplete="off"
      layout="vertical"
      className="article-edit-form"
    >
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-8">
          <Form.Item
            tooltip="Enter tag name"
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please enter tag name" }]}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            tooltip="Tag slug is generated automagically based on tag name"
            label="Slug"
            name="slug"
            rules={[{ required: true, message: "Please enter tag name" }]}
          >
            <Input disabled className="w-full" />
          </Form.Item>
          <Form.Item
            tooltip="Enter tag description"
            label="Description"
            name="description"
          >
            <Input.TextArea rows={4} className="w-full" />
          </Form.Item>
          <Divider>SEO</Divider>
          <Form.Item
            tooltip="SEO Title"
            label="SEO Title"
            name={["meta", "seo_title"]}
          >
            <Input placeholder="Enter SEO title" className="w-full" />
          </Form.Item>
          <Form.Item
            tooltip="SEO Keywords"
            label="SEO Keywords"
            name={["meta", "seo_keywords"]}
          >
            <Input
              placeholder="Enter SEO keywords (separated by comma)"
              className="w-full"
            />
          </Form.Item>
          <Form.Item
            tooltip="SEO Description"
            label="SEO Description"
            name={["meta", "seo_description"]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Enter SEO description"
              className="w-full"
            />
          </Form.Item>
          <Divider>Page Rich Description</Divider>
          <CKEditor
            className="w-full"
            value={description}
            onChange={(value) => {
              setDescription(value);
              form.setFieldsValue({
                meta: {
                  ...form.getFieldValue("meta"),
                  description: value,
                },
              });
            }}
          />

          <Divider>Tag Articles</Divider>
          <Table tag={params.tag} />
        </div>
        <div className="col-span-12 lg:col-span-4 h-full">
          <SidebarActions {...params} form={form} />
        </div>
      </div>
    </Form>
  );
}
