import { Table, Button } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";

export default function DataTable(params) {
  return (
    <Table
      onChange={params.onChange}
      pagination={params.pagination}
      dataSource={params.dataSource}
      loading={params.loading}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          align: "left",
          width: 600,
          render: (data, all) => (
            <div>
              <h4 className="block m-0 text-base">{data}</h4>
              <p className="block m-0 mb-2 text-sm text-slate-500">
                {all.description}
              </p>
            </div>
          ),
        },
        {
          title: "Parent",
          dataIndex: "parent",
          key: "parent",
          align: "center",
          render: (data) =>
            data && (
              <Link to={`/categories/edit/${data.id}`}>
                <Button
                  type="link"
                  icon={<LinkOutlined />}
                  className="block m-0 text-base"
                >
                  {data.name}
                </Button>
              </Link>
            ),
        },
        {
          title: "Created At",
          dataIndex: "created_at",
          key: "created_at",
          align: "center",
          render: (data) => moment(data).format("DD MMM YYYY — HH:mm"),
        },
        {
          dataIndex: "actions",
          key: "actions",
          align: "right",
          render: (_, all) => (
            <Link to={`/categories/edit/${all.id}`}>
              <Button type="primary">Edit Cateogory</Button>
            </Link>
          ),
        },
      ]}
    />
  );
}
