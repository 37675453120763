import axios from "axios";
import qs from "qs";
import * as Articles from "./articles";
import * as Stories from "./stories";
import * as Projects from "./projects";
import * as Categories from "./categories";
import * as ArticleTypes from "./articletypes";
import * as Authors from "./authors";
import * as Tags from "./tags";
import * as Users from "./users";
import * as Auth from "./auth";
import * as Profiles from "./profiles";
import * as ProfileCategories from "./profile_categories";
import * as Static from "./static";

axios.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };

  config.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };

  return config;
});

export {
  Articles,
  Projects,
  Categories,
  ArticleTypes,
  Authors,
  Tags,
  Users,
  Auth,
  Static,
  Stories,
  Profiles,
  ProfileCategories,
};
