import { useEffect, useState } from "react";
import { message, Divider, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Table from "./table";
import { Layouts } from "@components";
import * as API from "@api";

export default function Articles(params) {
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  useEffect(() => {
    getTableData();
    setLoading(false);
  }, []);

  async function getTableData(params = pagination) {
    setTableLoading(true);
    const _res = await API.Categories.getAll({
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      s: search,
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setTableData(_res.data.rows);
    setPagination({ ...params, total: _res.data.count });
    setTableLoading(false);
  }

  return (
    <Layouts.AdminLayout
      title="Categories"
      subtitle="Find, edit and publish categories on the website."
      loading={loading}
      extra={
        <Link to="/categories/new">
          <Button type="primary" icon={<PlusCircleOutlined />}>
            New Category
          </Button>
        </Link>
      }
    >
      <Table
        onChange={getTableData}
        pagination={pagination}
        dataSource={tableData}
        loading={tableLoading}
      />
    </Layouts.AdminLayout>
  );
}
