import { useState, useEffect, useRef } from "react";
import { message, Alert } from "antd";
import { useParams } from "react-router-dom";
import { Offline } from "react-detect-offline";
import { Layouts } from "@components";
import { LoadingOutlined } from "@ant-design/icons";
import Form from "./form";
import * as API from "@api";

export default function EditArticle(params) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const editorRef = useRef();
  const [article, setArticle] = useState(null);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [projects, setProjects] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    async function getInitData() {
      const [article, categories, types, projects, authors] = await Promise.all(
        [
          API.Articles.getOne({ id }),
          API.Categories.getAll({ limit: 50 }),
          API.ArticleTypes.getAll({ limit: 50 }),
          API.Projects.getAll({ limit: 50 }),
          API.Authors.getAll({ limit: 1000 }),
        ]
      );

      if (!article.data) {
        return message.error("Error loading article, try again later", 1);
      }

      setArticle(article.data);
      setCategories(categories.data.rows);
      setTypes(types.data.rows);
      setProjects(projects.data.rows);
      setAuthors(authors.data.rows);
      setPreviewImage(article.data.image);
      setLoading(false);
    }

    getInitData();
  }, []);

  const handleSave = async (data) => {
    setSaving(true);
    const blocks = await editorRef.current.save();

    // Get payload ready for API
    const payload = {
      ...data,
      id: article.id,
      blocks,
      project_id: data.project_id || null,
      image: previewImage || null,
      profile_id: data?.profile_id || null,
      authors: data.authors_raw,
      tags: data.tags_raw,
      published_at: data.published_at_raw.format(),
      tags_raw: null,
      authors_raw: null,
      published_at_raw: null,
      status: data.nextstatus || data.status || "draft",
    };

    // Update article
    const _res = await API.Articles.update(payload);

    setSaving(false);

    if (_res.error || _res instanceof Error) {
      return message.error("Error saving article, try again later", 1);
    }

    // console.log(_res)
    const categoryData = await API.Categories.getOne({
      id: payload.category_id,
    });
    const newPayload = {
      category: categoryData.data.slug,
      slug: payload.slug,
      id: payload.id,
    };

    // revalidate to FRONT SSG only if article is already published
    if (_res.data.updated[2].status === "published") {
      console.log("ревалидация индекса и страницы");
      await API.Articles.revalidateOne(newPayload);
    }

    return message.success("Article saved successfully", 1, () =>
      document.location.reload()
    );
  };

  return (
    <Layouts.AdminLayout title="Edit Article" loading={loading}>
      <Offline>
        <div className="offline-msgbox">
          <Alert
            className="msg"
            type="error"
            icon={<LoadingOutlined />}
            banner
            message="No Internet Connection"
            description="You are offline, please check your internet connection before saving anything."
          />
        </div>
      </Offline>
      <Form
        article={article}
        categories={categories}
        types={types}
        projects={projects}
        authors={authors}
        previewImage={previewImage}
        setPreviewImage={setPreviewImage}
        handleSave={handleSave}
        editorRef={editorRef}
        saving={saving}
      />
    </Layouts.AdminLayout>
  );
}
