import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export default function Search(params) {
  return (
    <Input.Search
      prefix={<SearchOutlined />}
      placeholder="Search article by title or subtitle"
      enterButton="Search"
      allowClear
      onSearch={params.onChange}
    />
  );
}
