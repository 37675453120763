import { useCallback } from "react";
import { createReactEditorJS } from "react-editor-js";
import plugins from "./plugins";

const EditorJS = createReactEditorJS();

export default function Editor(params) {
  return (
    <div className="col-span-12">
      <h3 className="text-lg">Article Content</h3>
      <EditorJS
        onInitialize={useCallback(
          (editor) => (params.editorRef.current = editor),
          []
        )}
        defaultValue={params.article.blocks}
        tools={plugins}
        placeholder="Start typing here ✍️"
      />
    </div>
  );
}
