import { Form, Button, Divider, Switch } from "antd";
import { SaveOutlined } from "@ant-design/icons";

export default function SidebarExtra(params) {
  return (
    <div className="article-edit-sideblock">
      <h6 className="m-0 text-base">Actions</h6>
      <Divider className="divider-base" />

      <Form.Item
        label="Published"
        tooltip="Select if project is published on the website"
        name="published"
        className="extra-radio"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item>
        <Button
          icon={<SaveOutlined />}
          type="primary"
          htmlType="submit"
          className="w-full my-5"
        >
          Save Udpates
        </Button>
      </Form.Item>
    </div>
  );
}
