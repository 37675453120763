import * as Pages from "@pages";
import * as EditorPages from "@pages/editor";

export default function EditorRoutes(props) {
  return [
    {
      path: "/articles",
      element: <EditorPages.Articles />,
    },
    {
      path: "/articles/new",
      element: <Pages.NewArticle />,
    },
    {
      path: "/articles/edit/:id",
      element: <Pages.EditArticle />,
    },
    {
      path: "/",
      element: <EditorPages.Dashboard />,
    },
    {
      path: "*",
      element: <Pages.NotFound />,
    },
  ];
}
