import { Form, Upload, message, Image, Button } from "antd";
import config from "@config";
import * as Icons from "@ant-design/icons";
import * as API from "@api";

const { Dragger } = Upload;

export default function Images(params) {
  return (
    <div className="grid grid-cols-3 gap-6">
      <div className="col-span-3 md:col-span-1">
        <h6 className="text-lg">Project Image</h6>
        <Image
          hidden={!params.image}
          className="w-full my-5"
          alt="Project Preview"
          src={`${config.s3}/${params.image}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/img/no-image.svg";
          }}
        />
        <Button
          hidden={!params.image}
          onClick={() => params.setImage(null)}
          block
          danger
          icon={<Icons.DeleteOutlined />}
        >
          Delete Image
        </Button>
        <Form.Item
          tooltip="Upload an image to be used as the project preview image"
          label="Image"
          name="image_upload"
          value={params.image}
          hidden={params.image}
        >
          <Dragger
            multiple={false}
            name="img"
            accept="image/*"
            customRequest={async ({ onSuccess, onError, file }) => {
              try {
                const _res = await API.Static.uploadImage(file);

                if (!_res.file) {
                  throw new Error();
                }

                params.setImage(_res.file.path);
                onSuccess();
              } catch (error) {
                onError();
                return message.error("Error uploading image", 2);
              }
            }}
          >
            <p className="ant-upload-drag-icon">
              <Icons.InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag image to upload</p>
            <p className="ant-upload-hint">Max. 3 MB allowed, PNG or JPEG</p>
          </Dragger>
        </Form.Item>
      </div>
      <div className="col-span-3 md:col-span-1">
        <h6 className="text-lg">Banner</h6>
        <Image
          hidden={!params.banner}
          className="w-full my-5"
          alt="Project Preview"
          src={`${config.s3}/${params.banner}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/img/no-image.svg";
          }}
        />
        <Button
          hidden={!params.banner}
          onClick={() => params.setBanner(null)}
          block
          danger
          icon={<Icons.DeleteOutlined />}
        >
          Delete Banner
        </Button>
        <Form.Item
          tooltip="Upload a banner to be used as the project banner on"
          label="Banner"
          name="banner_upload"
          value={params.banner}
          hidden={params.banner}
        >
          <Dragger
            multiple={false}
            name="img"
            accept="image/*"
            customRequest={async ({ onSuccess, onError, file }) => {
              try {
                const _res = await API.Static.uploadImage(file);

                if (!_res.file) {
                  throw new Error();
                }

                params.setBanner(_res.file.path);
                onSuccess();
              } catch (error) {
                onError();
                return message.error("Error uploading image", 2);
              }
            }}
          >
            <p className="ant-upload-drag-icon">
              <Icons.InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag image to upload</p>
            <p className="ant-upload-hint">Max. 3 MB allowed, PNG or JPEG</p>
          </Dragger>
        </Form.Item>
      </div>
      <div className="col-span-3 md:col-span-1">
        <h6 className="text-lg">Logo</h6>
        <Image
          hidden={!params.logo}
          className="w-full my-5"
          alt="Project Preview"
          src={`${config.s3}/${params.logo}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/img/no-image.svg";
          }}
        />
        <Button
          hidden={!params.logo}
          onClick={() => params.setLogo(null)}
          block
          danger
          icon={<Icons.DeleteOutlined />}
        >
          Delete Logo
        </Button>
        <Form.Item
          tooltip="Upload a logo to be used as the project logo"
          label="Logo"
          name="logo_upload"
          value={params.logo}
          hidden={params.logo}
        >
          <Dragger
            multiple={false}
            name="img"
            accept="image/*"
            customRequest={async ({ onSuccess, onError, file }) => {
              try {
                const _res = await API.Static.uploadImage(file);

                if (!_res.file) {
                  throw new Error();
                }

                params.setLogo(_res.file.path);
                onSuccess();
              } catch (error) {
                onError();
                return message.error("Error uploading image", 2);
              }
            }}
          >
            <p className="ant-upload-drag-icon">
              <Icons.InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag image to upload</p>
            <p className="ant-upload-hint">Max. 3 MB allowed, PNG or JPEG</p>
          </Dragger>
        </Form.Item>
      </div>
    </div>
  );
}
