import axios from "axios";
import config from "@config";

const API = `${config.api}/auth`;

// Возвращает активного пользователя
export const getUser = (params) =>
  axios
    .get(API, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Вход по email/password
export const login = (data) =>
  axios
    .post(`${API}/login`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Завершение сессии
export const logout = (data) =>
  axios
    .post(`${API}/logout`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Регистрация
export const register = (data) =>
  axios
    .post(`${API}/register`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));
