import axios from "axios";
import config from "@config";

const API = `${config.static}`;

// Загружает изображение через Static API
export const uploadImage = (data) => {
  const form = new FormData();
  form.append("img", data);

  return axios
    .post(`${API}/img/upload`, form, { withCredentials: true })
    .then((res) => {
      if (res.data.error) {
        return { success: false };
      }

      return { success: 1, file: res.data.data.file };
    })
    .catch((err) => ({ error: true, ...err.response.data }));
};

// Загружает видео через Static API
export const uploadVideo = (data) => {
  const form = new FormData();
  form.append("video", data);

  return axios
    .post(`${API}/vid/upload`, form, { withCredentials: true })
    .then((res) => {
      if (res.data.error) {
        return { success: false };
      }

      return { success: 1, file: res.data.data.file };
    })
    .catch((err) => ({ error: true, ...err.response.data }));
};
