import { Form, Button, Divider, Switch, message } from "antd";
import { SaveOutlined, LinkOutlined } from "@ant-design/icons";
import config from "@config";
import * as API from "@api";

export default function SidebarExtra({ form }) {
  const onRequestProfileCategory = async () => {
    if (form?.current) {
      const categoryId = form.current.getFieldValue("category_id");
      const slug = form.current.getFieldValue("slug");
      const published = form.current.getFieldValue("published");
      const category = await API.ProfileCategories.getOne({ id: categoryId });

      if (published) {
        return message.error(
          "Please check if this profile is not published. Otherwise, reload this page and try again.",
          3
        );
      }

      return window.open(`${config.frontend}/profiles/preview/${slug}`);
    }
  };

  return (
    <div className="article-edit-sideblock">
      <h6 className="m-0 text-base">Actions</h6>
      <Divider className="divider-base" />

      <Form.Item
        label="Published"
        tooltip="Select if project is published on the website"
        name="published"
        className="extra-radio"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item>
        <Button
          icon={<SaveOutlined />}
          type="primary"
          htmlType="submit"
          className="w-full mt-5"
        >
          Save Udpates
        </Button>
      </Form.Item>
      <Button
        block
        onClick={onRequestProfileCategory}
        type="link"
        icon={<LinkOutlined />}
      >
        Open Preview
      </Button>
    </div>
  );
}
