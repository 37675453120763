import {
  Form,
  Tag,
  Dropdown,
  Menu,
  Divider,
  Button,
  Input,
  Modal,
  message,
} from "antd";
import * as Icons from "@ant-design/icons";
import config from "@config";
import moment from "moment";
import * as API from "@api";

export default function SidebarActions(params) {
  const handleStatusChange = async ({ key }) => {
    Modal.info({
      title: "Article Status Change",
      closable: true,
      content: (
        <div>
          <p>
            You're about to change the article status to{" "}
            <Tag>{key.toUpperCase()}</Tag>
          </p>
          <p>Please confirm your action.</p>
        </div>
      ),
      onOk: async () => {
        // Rarely, form ref will be undefined if we load the page
        // and instantly change the status
        if (!params.form?.current) {
          return message.error(
            "Error changing article status, try again in a few minutes",
            3
          );
        }

        // Set next status
        params.form.current.setFieldsValue({ nextstatus: key });

        if (key === 'unpublished') {
          console.log('ревалидация индекса')
          await API.Articles.revalidateIndex(); 
        }

        // Trigger form submit
        params.form.current.submit();
      },
      onCancel: () => {},
      maskClosable: true,
      okText: "Confirm",
      cancelText: "Cancel",
    });
  };

  const getStatusTag = () => {
    switch (params.article.status) {
      case "draft":
        return <Tag>Draft</Tag>;
      case "in_review":
        return <Tag color="orange">In Review</Tag>;
      case "planned_publish":
        return <Tag color="blue">Planned Publish</Tag>;
      case "published":
        return <Tag color="green">Published</Tag>;
      case "unpublished":
        return <Tag color="red">Unpublished</Tag>;
      case "removed":
        return <Tag color="red">Archived</Tag>;

      default:
        break;
    }
  };

  return (
    <div className="article-edit-sideblock">
      <div className="flex flex-row justify-between align-middle mb-0">
        {getStatusTag()}
        <Dropdown.Button
          size="small"
          type="primary"
          loading={params.saving}
          onClick={() =>
            window.open(
              `${config.frontend}/preview/${params.article.id}/${params.article.slug}`,
              "_blank"
            )
          }
          overlay={
            <Menu>
              <Menu.Item
                onClick={handleStatusChange}
                disabled={params.article.status === "published"}
                icon={
                  <Icons.ArrowUpOutlined
                    style={{
                      color: params.article.status !== "published" && "#1890ff",
                    }}
                  />
                }
                key="published"
              >
                <span
                  className="m-0"
                  style={{
                    color: params.article.status !== "published" && "#1890ff",
                  }}
                >
                  Publish Now
                </span>
              </Menu.Item>
              <Menu.Item
                disabled
                icon={<Icons.CalendarOutlined />}
                key="planned_publish"
              >
                Planned Publish
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                disabled={params.article.status !== "published"}
                icon={<Icons.GlobalOutlined />}
                key="web"
              >
                <a
                  target="_blank"
                  href={`${config.frontend}/${params.article.category?.name}/${params.article.id}/${params.article.slug}`}
                >
                  Open on Website
                </a>
              </Menu.Item>
              <Menu.Item icon={<Icons.EyeOutlined />} key="preview">
                <a
                  target="_blank"
                  href={`${config.frontend}/preview/${params.article.id}/${params.article.slug}`}
                >
                  Open Preview
                </a>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                danger
                icon={<Icons.DeleteOutlined />}
                key="unpublished"
                disabled={params.article.status === "draft"}
                onClick={handleStatusChange}
              >
                Unpublish Now
              </Menu.Item>
            </Menu>
          }
        >
          <Icons.EyeOutlined /> Open Preview
        </Dropdown.Button>
      </div>
      <Divider className="mb-10" />
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-6">
          <h6 className="text-xs text-slate-500 m-0">Unique ID</h6>
          <Tag className="font-semibold">{params.article.id}</Tag>
        </div>
        <div className="col-span-6">
          <h6 className="text-xs text-slate-500 m-0">Last Edit</h6>
          <span className="text-xs font-semibold m-0">
            {moment(params.article.updated_at).format("DD MMM YYYY — HH:mm")}
          </span>
        </div>
        <div className="col-span-6">
          <h6 className="text-xs text-slate-500 m-0">Editor</h6>
          <span className="text-xs font-semibold m-0">
            {params.article.last_modified_user &&
              `${params.article.last_modified_user.first_name} ${params.article.last_modified_user.last_name}`}
          </span>
        </div>
        <div className="col-span-6">
          <h6 className="text-xs text-slate-500 m-0">TTS Generated</h6>
          <span className="text-xs font-semibold m-0">
            {params.article.tts ? "Yes" : "No"}
          </span>
        </div>
      </div>
      <Divider />
      <Form.Item name="nextstatus" hidden initialValue={params.article.status}>
        <Input disabled hidden />
      </Form.Item>
      <Form.Item>
        <Button
          icon={<Icons.SaveOutlined />}
          type="primary"
          htmlType="submit"
          className="w-full"
          loading={params.saving}
        >
          Save Changes
        </Button>
      </Form.Item>
    </div>
  );
}
