import { Fragment } from "react";
import { Form, Input, Divider, Upload, message, Button } from "antd";
import config from "@config";
import * as Icons from "@ant-design/icons";
import * as API from "@api";

const { Dragger } = Upload;

export default function FormMeta(params) {
  return (
    <Fragment>
      <div className="col-span-12">
        <Form.Item
          tooltip="Enter article title"
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please enter article title" }]}
        >
          <Input className="w-full" />
        </Form.Item>
        <Form.Item
          tooltip="Enter article subtitle"
          label="Subtitile"
          name="subtitle"
        >
          <Input.TextArea className="w-full" />
        </Form.Item>
        <Form.Item
          tooltip="Article slug is a normalized URL-type title, automatically generated"
          label="Slug"
          name="slug"
        >
          <Input disabled className="w-full" />
        </Form.Item>
      </div>
      <Divider className="col-span-12" />
      <div className="grid grid-cols-12 gap-6 col-span-12 w-full">
        <div className="col-span-12 lg:col-span-6">
          <h3 className="text-lg m-0">Preview Image</h3>
          <img
            className="w-full my-5"
            alt="Article Preview"
            src={`${config.s3}/${params.previewImage}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/img/no-image.svg";
            }}
          />
          <Form.Item
            tooltip="Write a caption for the image, will be shown under the image in the article"
            label="Image Caption"
            name="image_caption"
          >
            <Input placeholder="Enter caption" />
          </Form.Item>
        </div>
        <div className="col-span-12 md:col-span-6">
          <h3 className="text-lg m-0">Upload New Image</h3>
          <div className="w-full my-5">
            <Form.Item
              tooltip="Upload an image to be used as the article preview image"
              label="Image"
              name="image_upload"
              value={params.previewImage}
              hidden={params.previewImage}
            >
              <Dragger
                multiple={false}
                name="img"
                accept="image/*"
                // beforeUpload={() => null}
                customRequest={async ({ onSuccess, onError, file }) => {
                  try {
                    const _res = await API.Static.uploadImage(file);

                    if (!_res.file) {
                      throw new Error();
                    }

                    params.setPreviewImage(_res.file.path);
                    onSuccess();
                  } catch (error) {
                    onError();
                    return message.error("Error uploading image", 2);
                  }
                }}
              >
                <p className="ant-upload-drag-icon">
                  <Icons.InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag image to upload</p>
                <p className="ant-upload-hint">
                  Max. 3 MB allowed, PNG or JPEG
                </p>
              </Dragger>
            </Form.Item>
            <Button
              hidden={!params.previewImage}
              icon={<Icons.DeleteOutlined />}
              danger
              onClick={() => params.setPreviewImage(null)}
            >
              Delete Image
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
