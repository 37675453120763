import { useEffect } from "react";
import { message } from "antd";
import { Layouts } from "@components";
import * as API from "@api";

export default function NewProfile() {
  useEffect(() => {
    async function createProject() {
      const _res = await API.Profiles.create({ category_id: 1 });

      if (_res.error || !_res.data || typeof _res === Error) {
        return message.error("Something went wrong!");
      }

      return document.location.replace(`/profiles/edit/${_res.data.id}`);
    }

    createProject();
  }, []);

  return (
    <Layouts.AdminLayout
      title="New Profile"
      subtitle="Creating a new Profile for you, please wait…"
      loading
    />
  );
}
