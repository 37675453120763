import { useEffect, useState } from "react";
import { Form, Select, DatePicker, Spin } from "antd";
import * as API from "@api";
import debounce from "debounce";
import moment from "moment";

export default function FormBasics(params) {
  const [tags, setTags] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [fetchingProfile, setFetchingProfile] = useState(false);

  useEffect(() => {
    if (params?.article?.profile_id) {
      API.Profiles.getOne({ id: params.article.profile_id }).then((res) => {
        setProfiles([...profiles, res.data]);
      });
    }
  }, [params?.article?.profile_id]);

  return [
    <div className="col-span-12 md:col-span-6 lg:col-span-4">
      <Form.Item
        label="Type"
        name="type_id"
        tooltip="Select the global type of this article"
        rules={[{ required: true, message: "Please select article type" }]}
      >
        <Select
          className="w-full"
          placeholder="Select Article Type"
          optionFilterProp="children"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {params.types.map((data) => (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>,
    <div className="col-span-12 md:col-span-6 lg:col-span-4">
      <Form.Item
        label="Category"
        name="category_id"
        tooltip="Select the main category for this article"
        rules={[{ required: true, message: "Please select article category" }]}
      >
        <Select
          className="w-full"
          placeholder="Select Category"
          optionFilterProp="children"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {params.categories.map((data) => (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>,
    <div className="col-span-12 md:col-span-6 lg:col-span-4">
      <Form.Item
        label="Project"
        name="project_id"
        tooltip="If this article relates to the project, select here"
      >
        <Select
          className="w-full"
          placeholder="Select Project"
          allowClear
          optionFilterProp="children"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {params.projects.map((data) => (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>,
    <div className="col-span-12 md:col-span-6">
      <Form.Item
        label="Authors"
        name="authors_raw"
        rules={[
          {
            required: true,
            message: "Please enter at least one author",
          },
        ]}
        initialValue={params.article.authors.map(({ id }) => id)}
        tooltip="Select authors of the article, you can select one or more"
      >
        <Select
          className="w-full"
          placeholder="Select Project"
          allowClear
          mode="multiple"
          // optionFilterProp="id"
          // optionLabelProp="name"
          showSearch
          options={params.authors.map((data) => ({
            value: data.id,
            label: data.name,
          }))}
        />
      </Form.Item>
    </div>,
    <div className="col-span-12 md:col-span-6">
      <Form.Item
        label="Published Date"
        name="published_at_raw"
        initialValue={moment(
          params.article.published_at || params.article.created_at
        )}
        tooltip="Select 'Pulbished At' date of the article, this will be visible on the website and in the RSS feed"
        rules={[
          { required: true, message: "Please select article publish date" },
        ]}
      >
        <DatePicker
          inputReadOnly
          className="w-full"
          showTime
          format="DD MMM YYYY — HH:mm"
        />
      </Form.Item>
    </div>,
    <div className="col-span-12">
      <Form.Item
        label="Tags"
        name="tags_raw"
        rules={[
          {
            required: true,
            message: "Please enter at least one tag",
          },
        ]}
        initialValue={params.article.tags.map(({ id, name }) => name)}
        tooltip="Select tags of the article, you can select one or more"
      >
        <Select
          className="w-full"
          placeholder="Select Tags"
          allowClear
          filterOption={false}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          onSearch={debounce(async (search) => {
            if (!search) {
              return setTags([]);
            }
            setFetching(true);

            const _res = await API.Tags.searchAll({
              search,
              limit: 10,
            });

            setTags(_res.data.rows);
            setFetching(false);
          }, 500)}
          mode="tags"
          showSearch
          options={tags.map((data) => ({
            value: data.id,
            label: data.name,
          }))}
        />
      </Form.Item>
    </div>,
    <div className="col-span-12">
      <Form.Item
        label="Related Profile"
        name="profile_id"
        tooltip="Select the related profile for this article (optional)"
      >
        <Select
          className="w-full"
          placeholder="Start typing to search profile"
          allowClear
          filterOption={false}
          notFoundContent={fetchingProfile ? <Spin size="small" /> : null}
          onSearch={debounce(async (search) => {
            if (!search) {
              return setProfiles([]);
            }
            setFetchingProfile(true);

            const _res = await API.Profiles.getAll({
              s: search,
              limit: 10,
            });

            setProfiles(_res.data.rows);
            setFetchingProfile(false);
          }, 500)}
          showSearch
          options={profiles.map((data) => ({
            value: data.id,
            label: data.name,
          }))}
        />
      </Form.Item>
    </div>,
  ];
}
