import axios from "axios";
import config from "@config";

const API = `${config.api}/admin/articletypes`;

// Возвращает все типы материалов
export const getAll = (params) =>
  axios
    .get(API, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));
