import { useState, useEffect } from "react";
import { message, Alert, Form as FormInstance } from "antd";
import { useParams } from "react-router-dom";
import { Offline } from "react-detect-offline";
import { Layouts } from "@components";
import { LoadingOutlined } from "@ant-design/icons";
import Form from "./form";
import * as API from "@api";

export default function EditTAg(params) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [tag, setTag] = useState(null);
  const [form] = FormInstance.useForm();

  useEffect(() => {
    async function getInitData() {
      const [{ data }] = await Promise.all([API.Tags.getOne({ id })]).catch(
        (err) => {
          return message.error("Error loading data, try again later", 1);
        }
      );

      setTag(data.tag);
      setLoading(false);
    }

    getInitData();
  }, []);

  const handleSave = async (data) => {
    setSaving(true);

    data.meta.description = form.getFieldValue(["meta", "description"]);

    // Update tag
    const _res = await API.Tags.update({
      ...data,
      id: tag.id,
    });

    setSaving(false);

    if (_res.error || _res instanceof Error) {
      return message.error("Error saving data, try again later", 1);
    }

    return message.success("Tag saved successfully", 1);
  };

  return (
    <Layouts.AdminLayout title="Edit Tag" loading={loading}>
      <Offline>
        <div className="offline-msgbox">
          <Alert
            className="msg"
            type="error"
            icon={<LoadingOutlined />}
            banner
            message="No Internet Connection"
            description="You are offline, please check your internet connection before saving anything."
          />
        </div>
      </Offline>
      <Form form={form} tag={tag} handleSave={handleSave} saving={saving} />
    </Layouts.AdminLayout>
  );
}
