import { useState, useEffect, useRef } from "react";
import { message, Alert } from "antd";
import { useParams } from "react-router-dom";
import { Offline } from "react-detect-offline";
import { Layouts } from "@components";
import { LoadingOutlined } from "@ant-design/icons";
import Form from "./form";
import * as API from "@api";

export default function EditProject(params) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [project, setProject] = useState(null);
  const [image, setImage] = useState(null);
  const [banner, setBanner] = useState(null);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    async function getInitData() {
      const [res] = await Promise.all([API.Projects.getOne({ id })]).catch(
        (err) => {
          console.log("err", err);
          return message.error("Error loading data, try again later", 1);
        }
      );

      setProject(res.data);
      setImage(res.data.image);
      setBanner(res.data.banner);
      setLogo(res.data.logo);
      setLoading(false);
    }

    getInitData();
  }, []);

  const handleSave = async (data) => {
    setSaving(true);

    // Update article
    const _res = await API.Projects.update({
      ...data,
      image: image || null,
      logo: logo || null,
      banner: banner || null,
      id: project.id,
    });

    setSaving(false);

    if (_res.error || _res instanceof Error) {
      return message.error("Error saving data, try again later", 1);
    }

    return message.success("Project saved successfully", 1);
  };

  return (
    <Layouts.AdminLayout title="Edit Project" loading={loading}>
      <Offline>
        <div className="offline-msgbox">
          <Alert
            className="msg"
            type="error"
            icon={<LoadingOutlined />}
            banner
            message="No Internet Connection"
            description="You are offline, please check your internet connection before saving anything."
          />
        </div>
      </Offline>
      <Form
        project={project}
        handleSave={handleSave}
        saving={saving}
        image={image}
        setImage={setImage}
        logo={logo}
        setLogo={setLogo}
        banner={banner}
        setBanner={setBanner}
      />
    </Layouts.AdminLayout>
  );
}
