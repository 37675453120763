import { useState, useEffect } from "react";
import { message, Alert } from "antd";
import { useParams } from "react-router-dom";
import { Offline } from "react-detect-offline";
import { Layouts } from "@components";
import { LoadingOutlined } from "@ant-design/icons";
import Form from "./form";
import * as API from "@api";

export default function EditAuthor(params) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [author, setAuthor] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function getInitData() {
      const [{ data }, users] = await Promise.all([
        API.Authors.getOne({ id }),
        API.Users.getAll({ limit: 50 }),
      ]).catch((err) => {
        console.log("err", err);
        return message.error("Error loading data, try again later", 1);
      });

      setAuthor(data.author);
      setUsers(users.data.rows);
      setLoading(false);
    }

    getInitData();
  }, []);

  const handleSave = async (data) => {
    setSaving(true);

    // Update author
    const _res = await API.Authors.update({
      ...data,
      user_id: data.user_id || null,
      id: author.id,
    });

    setSaving(false);

    if (_res.error || _res instanceof Error) {
      return message.error("Error saving data, try again later", 1);
    }

    return message.success("Author saved successfully", 1);
  };

  return (
    <Layouts.AdminLayout title="Edit Author" loading={loading}>
      <Offline>
        <div className="offline-msgbox">
          <Alert
            className="msg"
            type="error"
            icon={<LoadingOutlined />}
            banner
            message="No Internet Connection"
            description="You are offline, please check your internet connection before saving anything."
          />
        </div>
      </Offline>
      <Form
        users={users}
        author={author}
        handleSave={handleSave}
        saving={saving}
      />
    </Layouts.AdminLayout>
  );
}
