import { useState, useEffect, useRef } from "react";
import { message, Alert } from "antd";
import { useParams } from "react-router-dom";
import { Offline } from "react-detect-offline";
import { Layouts } from "@components";
import { LoadingOutlined } from "@ant-design/icons";
import Form from "./form";
import * as API from "@api";

export default function EditCategory(params) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function getInitData() {
      const [cat, cats] = await Promise.all([
        API.Categories.getOne({ id }),
        API.Categories.getAll({ limit: 100 }),
      ]).catch((err) => {
        console.log("err", err);
        return message.error("Error loading data, try again later", 1);
      });

      setCategory(cat.data);
      setCategories(cats.data.rows);
      setLoading(false);
    }

    getInitData();
  }, []);

  const handleSave = async (data) => {
    setSaving(true);

    // Update article
    const _res = await API.Categories.update({
      ...data,
      parent_id: data.parent_id || null,
      id: category.id,
    });

    setSaving(false);

    if (_res.error || _res instanceof Error) {
      return message.error("Error saving data, try again later", 1);
    }

    return message.success("Category saved successfully", 1);
  };

  return (
    <Layouts.AdminLayout title="Edit Category" loading={loading}>
      <Offline>
        <div className="offline-msgbox">
          <Alert
            className="msg"
            type="error"
            icon={<LoadingOutlined />}
            banner
            message="No Internet Connection"
            description="You are offline, please check your internet connection before saving anything."
          />
        </div>
      </Offline>
      <Form
        category={category}
        categories={categories}
        handleSave={handleSave}
        saving={saving}
      />
    </Layouts.AdminLayout>
  );
}
