import { EditorLayout } from "@components/layouts";

export default function Dashboard(params) {
  return (
    <EditorLayout>
      <h1 className="m-auto text-center">Welcome to your Dashboard</h1>
      <p className="m-auto mt-2 text-center max-w-md text-slate-500">
        Start writing your <a href="/articles">new article</a>. More content and
        tools will be available later on this page.
      </p>
      <img
        src="/img/dashboard-welcome.jpg"
        alt="Welcome"
        className="block max-w-3xl m-auto"
      />
    </EditorLayout>
  );
}
