import { Form, Switch, Divider } from "antd";

export default function SidebarExtra(params) {
  return (
    <div className="article-edit-sideblock">
      <h6 className="m-0 text-base">Extra</h6>
      <Divider className="divider-base" />

      <Form.Item
        label="Push Notifications (Web)"
        tooltip="Select if you want to send a push notifications to subscribed website visitors once you publish an article"
        name={["meta", "push_notifications_web"]}
        className="extra-radio"
        valuePropName="checked"
      >
        <Switch disabled />
      </Form.Item>

      <Form.Item
        label="Text-to-Speech (Web)"
        tooltip="Select if you want to generate a text-to-speech version of the article once you publish an article"
        name={["meta", "generate_tts"]}
        className="extra-radio"
        valuePropName="checked"
      >
        <Switch disabled />
      </Form.Item>
    </div>
  );
}
