import { Form, Button, Divider } from "antd";
import { SaveOutlined } from "@ant-design/icons";

export default function SidebarExtra(params) {
  return (
    <div className="article-edit-sideblock">
      <h6 className="m-0 text-base">Actions</h6>
      <Divider className="divider-base" />

      <Form.Item>
        <Button
          icon={<SaveOutlined />}
          type="primary"
          htmlType="submit"
          className="w-full my-5"
        >
          Save &amp; Publish
        </Button>
      </Form.Item>
    </div>
  );
}
