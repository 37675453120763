import { Table, Tag, Menu, Dropdown, message } from "antd";
import config from "@config";
import * as API from "@api";
import * as Icons from "@ant-design/icons";

export default function DataTable(params) {
  function getStatus(data) {
    switch (data) {
      case "draft":
        return <Tag>Draft</Tag>;
      case "in_review":
        return <Tag color="blue">In Review</Tag>;
      case "planned_publish":
        return <Tag color="orange">Planned Publish</Tag>;
      case "published":
        return <Tag color="green">Published</Tag>;
      case "unpublished":
        return <Tag color="red">Unpublished</Tag>;
      case "removed":
        return <Tag color="red">removed</Tag>;

      default:
        break;
    }
  }

  const handleMenuClick = async ({ key, all }) => {
    switch (key) {
      case "edit":
        return window.open(`/articles/edit/${all.id}`);
      case "web":
        return window.open(
          `${config.frontend}/${all.category.slug}/${all.id}/${all.slug}`
        );
      case "preview":
        return window.open(`${config.frontend}/preview/${all.id}/${all.slug}`);
      case "published":
      case "unpublished":
        const loading = message.loading(
          "Article status change in progress...",
          0
        );
        const _res = await API.Articles.update({ status: key, id: all.id });
        loading();

        if (_res.error || typeof _res === Error) {
          return message.error("Something went wrong!");
        }

        return message.success(
          "Article status changed successfully!",
          1,
          () => {
            document.location.reload();
          }
        );

      default:
        break;
    }
  };

  return (
    <Table
      onChange={params.onChange}
      pagination={params.pagination}
      dataSource={params.dataSource}
      loading={params.loading}
      columns={[
        {
          dataIndex: "image",
          key: "image",
          align: "center",
          width: 150,
          render: (data) => (
            <img
              src={data ? `${config.s3}/${data}` : "/img/no-image.svg"}
              className="block w-full"
              style={{ maxWidth: 150 }}
              alt={data}
            />
          ),
        },
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
          align: "left",
          width: 600,
          render: (data, all) => (
            <div>
              <h4 className="block m-0 text-base">{data}</h4>
              <p className="block m-0 mb-2 text-sm text-slate-500">
                {all.subtitle}
              </p>
              {[
                all.is_breaking && (
                  <Tag style={{ marginRight: 5 }} color="red">
                    Breaking
                  </Tag>
                ),
                all.is_important && (
                  <Tag style={{ marginRight: 5 }} color="blue">
                    Important
                  </Tag>
                ),
                all.is_pr && <Tag style={{ margin: 5 }}>Promoted</Tag>,
                all.is_live && (
                  <Tag style={{ marginRight: 5 }} color="red">
                    Live
                  </Tag>
                ),
                all.is_editor_choice && (
                  <Tag style={{ marginRight: 5 }}>Editor Choice</Tag>
                ),
                all.in_slider && (
                  <Tag style={{ marginRight: 5 }}>In Slider / Head</Tag>
                ),
              ]}
            </div>
          ),
        },
        {
          title: "Category",
          dataIndex: "category",
          key: "category",
          align: "center",
          width: 140,
          render: (data) =>
            data && <h6 className="m-0 text-sm">{data.name}</h6>,
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          align: "center",
          width: 140,
          render: (data) =>
            data && <h6 className="m-0 text-sm">{data.name}</h6>,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          align: "center",
          width: 140,
          render: getStatus,
        },
        {
          title: "Project",
          dataIndex: "project",
          key: "project",
          align: "center",
          width: 160,
          render: (data) =>
            data && <h6 className="m-0 text-sm">{data.name}</h6>,
        },
        {
          title: "Editor",
          dataIndex: "last_modified_user",
          key: "last_modified_user",
          align: "center",
          width: 200,
          render: (data) =>
            data && (
              <h6 className="m-0 text-sm">
                {data.first_name} {data.last_name}
              </h6>
            ),
        },
        {
          dataIndex: "actions",
          key: "actions",
          align: "right",
          render: (data, all) => (
            <Dropdown.Button
              type="primary"
              onClick={() =>
                (document.location.href = `/articles/edit/${all.id}`)
              }
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={({ key }) => handleMenuClick({ key, all })}
                    icon={<Icons.EditOutlined />}
                    key="edit"
                  >
                    Edit Article
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    onClick={({ key }) => handleMenuClick({ key, all })}
                    icon={<Icons.ArrowUpOutlined />}
                    key="published"
                    disabled={all.status === "published"}
                  >
                    Publish Now
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    onClick={({ key }) => handleMenuClick({ key, all })}
                    icon={<Icons.GlobalOutlined />}
                    key="web"
                    disabled={all.status !== "published"}
                  >
                    Open on Website
                  </Menu.Item>
                  <Menu.Item
                    onClick={({ key }) => handleMenuClick({ key, all })}
                    icon={<Icons.EyeOutlined />}
                    key="preview"
                  >
                    Open Preview
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    onClick={({ key }) => handleMenuClick({ key, all })}
                    danger
                    icon={<Icons.DeleteOutlined />}
                    key="unpublished"
                  >
                    Unpublish Now
                  </Menu.Item>
                </Menu>
              }
            >
              Edit Article
            </Dropdown.Button>
          ),
        },
      ]}
    />
  );
}
