import Header from "@editorjs/header";
import Paragraph from "editorjs-paragraph-with-alignment";
import TextColors from "editorjs-text-color-plugin";
import List from "@editorjs/list";
import Quote from "@editorjs/quote";
import Delimiter from "@editorjs/delimiter";
import Embed from "@editorjs/embed";
import Raw from "@editorjs/raw";
import Table from "@editorjs/table";
import Underline from "@editorjs/underline";
import Image from "./image";
import Carousel from '@vietlongn/editorjs-carousel';
import EmbededArticle from "./embeded-article";
import * as API from "@api";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  header: {
    class: Header,
    inlineToolbar: true,
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  color: {
    class: TextColors,
    config: {
      type: "text",
    },
  },
  marker: {
    class: TextColors,
    config: {
      type: "marker",
    },
  },
  list: {
    class: List,
    inlineToolbar: true,
  },
  quote: {
    class: Quote,
    inlineToolbar: true,
  },
  delimiter: {
    class: Delimiter,
    inlineToolbar: true,
  },
  Embed: {
    class: Embed,
    config: {
      services: {
        youtube: true,
        vimeo: true,
        twitter: true,
        facebook: true,
        instagram: true,
        twitch: true,
        miro: true,
        gfycat: true,
        imgur: true,
      },
    },
  },
  material: {
    class: EmbededArticle,
    inlineToolbar: true
  },
  table: {
    class: Table,
    inlineToolbar: true,
  },
  rawTool: {
    class: Raw,
    inlineToolbar: true,
  },
  underline: {
    class: Underline,
    inlineToolbar: true,
  },
  image: {
    class: Image,
    inlineToolbar: true,
    config: {
      captionPlaceholder: "Image caption",
      uploader: {
        async uploadByFile(file) {
          return await API.Static.uploadImage(file);
        },
      },
    },
  },
  // sideimage: {
  //   class: SideImage,
  //   inlineToolbar: true,
  //   config: {
  //     captionPlaceholder: "Текст изображения",
  //     uploader: {
  //       async uploadByFile(file) {
  //         return await Static.uploadImage(file);
  //       },
  //     },
  //   },
  // },
  carousel: {
    class: Carousel,
    inlineToolbar: true,
    config: {
      captionPlaceholder: "Текст изображения",
      uploader: {
        async uploadByFile(file) {
          return await API.Static.uploadImage(file);
        },
      },
    },
  },
  // quote: {
  //   class: Quote,
  //   inlineToolbar: true,
  // },
  // personality: {
  //   class: Personality,
  //   inlineToolbar: true,
  // },
  // embeddedarticle: {
  //   class: EmbeddedArticle,
  //   inlineToolbar: true,
  // },
  // delimiter: {
  //   class: Delimiter,
  //   inlineToolbar: true,
  // },
  // embed: {
  //   class: Embed,
  //   config: {
  //     services: {
  //       youtube: true,
  //       vimeo: true,
  //     },
  //   },
  // },
  // table: {
  //   class: Table,
  //   inlineToolbar: true,
  // },
  // rawTool: {
  //   class: Raw,
  //   inlineToolbar: true,
  // },
  // underline: {
  //   class: Underline,
  //   inlineToolbar: true,
  // },
};
