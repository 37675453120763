import { createRef } from "react";
import { Form, Divider } from "antd";
import Editor from "./editor";
import Basics from "./basics";
import Meta from "./meta";
import SidebarActions from "./sidebar/actions";
import SidebarAppearance from "./sidebar/appearance";
import SidebarExtra from "./sidebar/extra";

export default function EditForm(params) {
  const form = createRef();

  return (
    <Form
      name="article"
      ref={form}
      initialValues={params.article}
      onFinish={params.handleSave}
      autoComplete="off"
      layout="vertical"
      className="article-edit-form"
    >
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-8">
          <div className="grid grid-cols-12 gap-6 gap-y-0">
            <Basics {...params} />
            <Divider className="col-span-12" />
            <Meta {...params} />
            <Divider className="col-span-12" />
            <Editor form={form} {...params} />
          </div>
        </div>
        <div className="col-span-12 lg:col-span-4 h-full">
          <SidebarActions {...params} form={form} />
          <SidebarAppearance {...params} />
          <SidebarExtra {...params} />
        </div>
      </div>
    </Form>
  );
}
