import { createRef, useState } from "react";
import { Form, Input, Select, Divider, Upload, message } from "antd";
import SidebarActions from "./sidebar/actions";
import Images from "./images";

export default function EditForm(params) {
  const form = createRef();

  return (
    <Form
      name="project"
      ref={form}
      initialValues={params.project}
      onFinish={params.handleSave}
      autoComplete="off"
      layout="vertical"
      className="article-edit-form"
    >
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-8">
          <Form.Item
            tooltip="Enter project name"
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please enter project name" }]}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            tooltip="Slug will be generated automatically"
            label="Slug"
            name="slug"
            rules={[
              {
                required: true,
                message: "Please enter project name for slug generation",
              },
            ]}
          >
            <Input disabled className="w-full" />
          </Form.Item>
          <Form.Item
            tooltip="Enter project description"
            label="Description"
            name="description"
          >
            <Input.TextArea rows={4} className="w-full" />
          </Form.Item>
          <Divider />
          <Images {...params} />
        </div>
        <div className="col-span-12 lg:col-span-4 h-full">
          <SidebarActions {...params} form={form} />
        </div>
      </div>
    </Form>
  );
}
