import axios from "axios";
import config from "@config";

const API = `${config.api}/admin/articles`;
const FRONT_REVALIDATE_API = `${config.frontend}/api/revalidate?secret=${config.revalidate}`;
const FRONT_REVALIDATE_INDEX_API = `${config.frontend}/api/revalidate-main?secret=${config.revalidate}`;

// Возвращает все материалы
export const getAll = (params) =>
  axios
    .get(API, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Возвращает материал
export const getOne = (params) =>
  axios
    .get(`${API}/id/${params.id}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Обновляет материал
export const update = (data) =>
  axios
    .post(`${API}/update/id/${data.id}`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Обновляет материал
export const create = (data = null) =>
  axios
    .post(API, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => ({ error: true, ...err.response.data }));

// Ревалидация новости для NEXT SSG
export const revalidateOne = (data) => 
  axios.post(FRONT_REVALIDATE_API, data)

// Ревалидация главной страницы для NEXT SSG
export const revalidateIndex = () => 
  axios.get(FRONT_REVALIDATE_INDEX_API)
