import { React } from 'react';
import ReactDOM from 'react-dom';

import Input from './input';

import './index.scss';

export default class EmbededArticle {
  static get toolbox() {
    return {
      title: 'Material',
      icon: `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
            <rect width="20" height="20" fill="transparent"/>
            <path d="M1.75 3.58334H18.25V5.41668H1.75V3.58334ZM1.75 16.4167H18.25V14.5833H1.75V16.4167ZM1.75 12.75H9.08333V7.25001H1.75V12.75ZM10.9167 12.75H18.25V10.9167H10.9167V12.75ZM10.9167 9.08334H18.25V7.25001H10.9167V9.08334Z"/>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="20" height="20" fill="white"/>
            </clipPath>
          </defs>
        </svg>      
      `
    };
  }

  constructor({ data }) {
    this.data = {
      items: data.items || []
    };
    this.nodes = {
      holder: null
    }
  }

  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode;

    const onDataChange = (newData) => {
      this.data = {
        ...newData,
      }
    };

    ReactDOM.render(
      <Input data={this.data} onDataChange={onDataChange} />, rootNode
    )

    return this.nodes.holder
  }

  save() {
    return this.data;
  }
}
