import { useEffect, useState } from "react";
import { message, Divider, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Table from "./table";
import StatusFilter from "./filters/status";
import CategoryFilter from "./filters/categories";
import TypeFilter from "./filters/types";
import ProjectFilter from "./filters/projects";
import SearchFilter from "./filters/search";
import { Layouts } from "@components";
import * as API from "@api";

export default function Articles(params) {
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState(null);
  const [filters, setFilters] = useState({
    categories: [],
    projects: [],
    types: [],
  });
  const [filter, setFilter] = useState({
    category_id: undefined,
    project_id: undefined,
    type_id: undefined,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  useEffect(() => {
    const getFiltersData = async () => {
      const [categories, projects, types] = await Promise.all([
        API.Categories.getAll({ limit: 50 }),
        API.Projects.getAll({ limit: 50 }),
        API.ArticleTypes.getAll({ limit: 50 }),
      ]);

      setFilters(
        {
          categories: categories.data.rows,
          projects: projects.data.rows,
          types: types.data.rows,
        },
        setLoading(false)
      );
    };

    getFiltersData();
    getTableData();
  }, [filter, search]);

  async function getTableData(params = pagination) {
    setTableLoading(true);
    const _res = await API.Articles.getAll({
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      s: search,
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setTableData(_res.data.rows);
    setPagination({ ...params, total: _res.data.count });
    setTableLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  return (
    <Layouts.AdminLayout
      title="Articles"
      subtitle="Find, edit and publish articles on the website."
      loading={loading}
      extra={
        <Link to="/articles/new">
          <Button type="primary" icon={<PlusCircleOutlined />}>
            New Article
          </Button>
        </Link>
      }
    >
      <Divider>Search &amp; Filter</Divider>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <StatusFilter onChange={onFilterChange} />
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <CategoryFilter data={filters.categories} onChange={onFilterChange} />
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <TypeFilter data={filters.types} onChange={onFilterChange} />
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <ProjectFilter data={filters.projects} onChange={onFilterChange} />
        </div>
        <div className="col-span-12">
          <SearchFilter
            onChange={(v) => {
              setSearch(v);
              setPagination({ ...pagination, current: 1 });
            }}
          />
        </div>
      </div>
      <Divider />

      <Table
        onChange={getTableData}
        pagination={pagination}
        dataSource={tableData}
        loading={tableLoading}
      />
    </Layouts.AdminLayout>
  );
}
