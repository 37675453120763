import { createRef } from "react";
import { Form, Divider } from "antd";
import Basics from "./basics";
import Meta from "./meta";
import Slides from "./slides";
import SidebarActions from "./sidebar/actions";

export default function EditForm(params) {
  const form = createRef();

  return (
    <Form
      name="story"
      ref={form}
      initialValues={params.story}
      onFinish={params.handleSave}
      autoComplete="off"
      layout="vertical"
      className="stories-edit-form"
    >
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-8">
          <div className="grid grid-cols-12 gap-6 gap-y-0">
            <Basics {...params} />
            <Divider className="col-span-12" />
            <Meta {...params} />
            <Divider className="col-span-12" />
            <h3 className="text-lg mb-5">Slides</h3>
            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <Slides {...params} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-4 h-full">
          <SidebarActions {...params} form={form} />
        </div>
      </div>
    </Form>
  );
}
