import { useEffect, useState } from "react";
import { message, Divider, Button, Input } from "antd";
import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Table from "./table";
import { Layouts } from "@components";
import * as API from "@api";

export default function Authors(params) {
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  useEffect(() => {
    getTableData();
    setLoading(false);
  }, [search]);

  function onSearch(s) {
    setSearch(s, setPagination({ ...pagination, current: 1 }));
  }

  async function getTableData(params = pagination) {
    setTableLoading(true);
    const _res = await API.Authors.getAll({
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      filter: search ? { name: search } : null,
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setTableData(_res.data.rows);
    setPagination({ ...params, total: _res.data.count });
    setTableLoading(false);
  }

  return (
    <Layouts.AdminLayout
      title="Authors"
      subtitle="Find, edit and publish article authors on the website."
      loading={loading}
      extra={
        <Link to="/authors/new">
          <Button type="primary" icon={<PlusCircleOutlined />}>
            New Author
          </Button>
        </Link>
      }
    >
      <Divider>Search Authors</Divider>
      <Input.Search
        prefix={<SearchOutlined />}
        placeholder="Search authors by their name"
        enterButton="Search"
        allowClear
        onSearch={onSearch}
      />
      <Divider />
      <Table
        onChange={getTableData}
        pagination={pagination}
        dataSource={tableData}
        loading={tableLoading}
      />
    </Layouts.AdminLayout>
  );
}
