import { Layouts } from "@components";

export default function NotFound(params) {
  return (
    <Layouts.AdminLayout>
      <div className="flex w-full h-full justify-center align-middle">
        <div>
          <h3 className="text-center">Not Found</h3>
          <p className="text-center">
            The page you're looking for doesn't exist or has been removed.
            Please <a href="mailto:digital@musan.kz">contact us</a> if you think
            this is a mistake.
          </p>
        </div>
      </div>
    </Layouts.AdminLayout>
  );
}
