import { Select } from "antd";

export default function Status(params) {
  return (
    <Select
      className="w-full"
      placeholder="Status"
      allowClear
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => params.onChange("status", v)}
    >
      <Select.Option value="draft">Draft</Select.Option>
      <Select.Option value="in_review">In Review</Select.Option>
      <Select.Option value="planned_publish">Planned Publish</Select.Option>
      <Select.Option value="published">Published</Select.Option>
      <Select.Option value="unpublished">Unpublished</Select.Option>
      <Select.Option value="removed">Archived</Select.Option>
    </Select>
  );
}
