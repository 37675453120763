import { useLocation } from "react-router-dom";
import Form from "./form";
import config from "@config";

export default function Signin() {
  const isRegister = useLocation().pathname === "/register";

  return (
    <div
      className="login-page"
      style={{
        background: "url('/img/bgr.jpg') center center no-repeat",
        backgroundSize: "cover",
      }}
    >
      <a className="logo" href={config.basepath} alt="The Qazaqstan Monitor">
        <img src="/img/logo.svg" alt="The Qazaqstan Monitor" />
      </a>
      <div className="container content-max">
        <div className="grid grid-cols-12 gap-0 gap bg-white rounded-lg shadow-lg divide-x overflow-hidden my-10">
          <div className="col-span-12 sm:col-span-6 lg:col-span-5">
            <div className="login-container">
              <div className="form-layout p-7">
                <Form isRegister={isRegister} />
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 lg:col-span-7">
            <div className="login-container h-full">
              <div
                className="form-layout p-10 h-full flex flex-col justify-end"
                style={{
                  background:
                    'url("/img/login-background.jpg") center bottom no-repeat',
                  backgroundSize: "cover",
                  minHeight: 300,
                }}
              >
                <span className="display font-medium text-2xl mb-3 text-white w-full">
                  Unleash your writing potential.
                </span>
                <span className="display font-normal text-lg text-white w-full">
                  Publish your articles on The Qazaqstan Monitor and get noticed
                  by thousands of readers around the world.
                </span>
                <a href="/register" className="text-base w-fit mt-5">
                  Register Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <copy className="disclaimer">
        © 2021 – {new Date().getFullYear()} | The Qazaqstan Monitor
      </copy>
    </div>
  );
}
