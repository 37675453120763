import React, { useEffect, useState, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import * as Pages from "@pages";
import * as API from "@api";
import { RoutesHandler } from "@components";
import AdminRoutes from "@components/routes/admin";

export default function App(params) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function getInitialData() {
      const [{ data }] = await Promise.all([API.Auth.getUser()]);

      setUser(data);
      setLoading(false);
    }

    getInitialData();
  }, []);

  return (
    <Routes>
      <Route
        path="/login"
        element={<Pages.Signin loading={loading} user={user} />}
      />
      <Route
        path="/register"
        element={<Pages.Signin loading={loading} user={user} />}
      />
      <Route
        path="/logout"
        element={<Pages.Logout loading={loading} user={user} />}
      />
      <Route
        path="*"
        element={<RoutesHandler loading={loading} user={user} />}
      />
    </Routes>
  );
}
