import { Form, Switch, Divider } from "antd";

export default function SidebarAppearance(params) {
  return (
    <div className="article-edit-sideblock">
      <h6 className="m-0 text-base">Appearance</h6>
      <Divider className="divider-base" />

      <Form.Item
        label="In Slider (TOP-5)"
        tooltip="Select if article should appear at the top of homepage"
        name="in_slider"
        className="extra-radio"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label="Large Preview"
        tooltip="Select if article should appear as large preview on homepage"
        name="in_head"
        className="extra-radio"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label="Breaking"
        tooltip="Select if article is breaking"
        name="is_breaking"
        className="extra-radio"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label="Important"
        tooltip="Select if article is important"
        name="is_important"
        className="extra-radio"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label="Editor's Choice"
        tooltip="Select if article is Editor's Choice"
        name="is_editor_choice"
        className="extra-radio"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label="PR"
        tooltip="Select if article is Commercially Promoted"
        name="is_pr"
        className="extra-radio"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label="Live"
        tooltip="Select if article is Live or has live broadcast"
        name="is_live"
        className="extra-radio"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </div>
  );
}
