import * as Pages from "@pages";

export default function AdminRoutes(props) {
  return [
    {
      path: "/profiles/new",
      element: <Pages.NewProfile />,
    },
    {
      path: "/profiles/edit/:id",
      element: <Pages.EditProfile />,
    },
    {
      path: "/profiles",
      element: <Pages.Profiles />,
    },
    {
      path: "/authors/edit/:id",
      element: <Pages.EditAuthor />,
    },
    {
      path: "/authors/new",
      element: <Pages.NewAuthor />,
    },
    {
      path: "/authors",
      element: <Pages.Authors />,
    },
    {
      path: "/tags/edit/:id",
      element: <Pages.EditTag />,
    },
    {
      path: "/tags/new",
      element: <Pages.NewTag />,
    },
    {
      path: "/tags",
      element: <Pages.Tags />,
    },
    {
      path: "/projects/new",
      element: <Pages.NewProject />,
    },
    {
      path: "/projects/edit/:id",
      element: <Pages.EditProject />,
    },
    {
      path: "/projects",
      element: <Pages.Projects />,
    },
    {
      path: "/categories",
      element: <Pages.Categories />,
    },
    {
      path: "/categories/edit/:id",
      element: <Pages.EditCategory />,
    },
    {
      path: "/categories/new",
      element: <Pages.NewCategory />,
    },
    {
      path: "/articles",
      element: <Pages.Articles />,
    },
    {
      path: "/articles/new",
      element: <Pages.NewArticle />,
    },
    {
      path: "/articles/edit/:id",
      element: <Pages.EditArticle />,
    },
    {
      path: "/stories",
      element: <Pages.Stories />,
    },
    {
      path: "/stories/new",
      element: <Pages.NewStory />,
    },
    {
      path: "/stories/edit/:id",
      element: <Pages.EditStory />,
    },
    {
      path: "/",
      element: <Pages.Dashboard />,
    },
    {
      path: "*",
      element: <Pages.NotFound />,
    },
  ];
}
