module.exports = {
  env: process.env.NODE_ENV || "development",
  port: process.env.PORT || process.env.REACT_APP_PORT,
  api: process.env.REACT_APP_API_ENDPOINT,
  static: process.env.REACT_APP_STATIC_ENDPOINT,
  frontend: process.env.REACT_APP_FRONTEND_ENDPOINT,
  revalidate: process.env.REACT_APP_REVALIDATE_SECRET,
  basepath: process.env.REACT_APP_BASEPATH,
  recaptcha_key: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY,
  s3: process.env.REACT_APP_S3_ENDPOINT,
};
