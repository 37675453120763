import { useState, useEffect } from "react";
import { message, Alert } from "antd";
import { useParams } from "react-router-dom";
import { Offline } from "react-detect-offline";
import { Layouts } from "@components";
import { LoadingOutlined } from "@ant-design/icons";
import Form from "./form";
import moment from "moment";
import * as API from "@api";

export default function EditProfile(params) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [profile, setProfile] = useState(null);
  const [image, setImage] = useState(null);
  const [bio, setBio] = useState(null);

  useEffect(() => {
    async function getInitData() {
      const [res] = await Promise.all([API.Profiles.getOne({ id })]).catch(
        (err) => {
          console.log("err", err);
          return message.error("Error loading data, try again later", 1);
        }
      );

      res.data.birthdate = res.data.birthdate
        ? moment(res.data.birthdate)
        : null;

      setProfile(res.data);
      setImage(res.data.image);
      setBio(res.data.bio);
      setLoading(false);
    }

    getInitData();
  }, []);

  const handleSave = async (data) => {
    setSaving(true);

    data.birthdate = data.birthdate ? data.birthdate.format() : null;

    // Update article
    const _res = await API.Profiles.update({
      ...data,
      image: image || null,
      bio: bio || null,
      id: profile.id,
    });

    setSaving(false);

    if (_res.error || _res instanceof Error) {
      return message.error("Error saving data, try again later", 1);
    }

    return message.success("Profile saved successfully", 1);
  };

  return (
    <Layouts.AdminLayout title="Edit Profile" loading={loading}>
      <Offline>
        <div className="offline-msgbox">
          <Alert
            className="msg"
            type="error"
            icon={<LoadingOutlined />}
            banner
            message="No Internet Connection"
            description="You are offline, please check your internet connection before saving anything."
          />
        </div>
      </Offline>
      <Form
        profile={profile}
        handleSave={handleSave}
        saving={saving}
        image={image}
        setImage={setImage}
        bio={bio}
        setBio={setBio}
      />
    </Layouts.AdminLayout>
  );
}
