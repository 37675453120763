import { Form, Select, DatePicker } from "antd";
import moment from "moment";

export default function FormBasics(params) {
  return [
    <div className="col-span-12 md:col-span-6 lg:col-span-4">
      <Form.Item
        label="Category"
        name="category_id"
        tooltip="Select the main category for this article"
        rules={[{ required: true, message: "Please select article category" }]}
      >
        <Select
          className="w-full"
          placeholder="Select Category"
          optionFilterProp="children"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {params.categories.map((data) => (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>,
    <div className="col-span-12 md:col-span-6 lg:col-span-4">
      <Form.Item
        label="Project"
        name="project_id"
        tooltip="If this article relates to the project, select here"
      >
        <Select
          className="w-full"
          placeholder="Select Project"
          allowClear
          optionFilterProp="children"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {params.projects.map((data) => (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>,
    <div className="col-span-12 md:col-span-4">
      <Form.Item
        label="Published Date"
        name="published_at_raw"
        initialValue={moment(
          params.story.published_at || params.story.created_at
        )}
        tooltip="Select 'Pulbished At' date of the article, this will be visible on the website and in the RSS feed"
        rules={[
          { required: true, message: "Please select article publish date" },
        ]}
      >
        <DatePicker
          inputReadOnly
          className="w-full"
          showTime
          format="DD MMM YYYY — HH:mm"
        />
      </Form.Item>
    </div>,
  ];
}
