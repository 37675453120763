import { useState, useEffect, useRef } from "react";
import { message, Alert } from "antd";
import { useParams } from "react-router-dom";
import { Offline } from "react-detect-offline";
import { Layouts } from "@components";
import { LoadingOutlined } from "@ant-design/icons";
import Form from "./form";
import * as API from "@api";
import * as R from "ramda";

export default function EditArticle(params) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [story, setStory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [preview, setPreview] = useState({
    image: null,
    video: null,
  });

  useEffect(() => {
    async function getInitData() {
      const [story, categories, projects] = await Promise.all([
        API.Stories.getOne({ id }),
        API.Categories.getAll({ limit: 50 }),
        API.Projects.getAll({ limit: 50 }),
      ]);

      if (!story.data) {
        return message.error("Error loading story, try again later", 1);
      }

      setStory(story.data);
      setCategories(categories.data.rows);
      setProjects(projects.data.rows);
      setPreview({
        image: story.data.preview_image,
        video: story.data.preview_video,
      });
      setLoading(false);
    }

    getInitData();
  }, []);

  const handleSave = async (data) => {
    setSaving(true);

    const {
      title,
      subtitle,
      published_at_raw,
      category_id = null,
      project_id = null,
      nextstatus = null,
    } = data;
    const preview_image = R.pathOr(null, ["image"], preview);
    const slides = R.pathOr([], ["slides"], story);

    if (R.isEmpty(slides) || !preview_image) {
      setSaving(false);
      return message.error(
        "Please add at least one slide and a preview image",
        1
      );
    }

    // Get payload ready for API
    const payload = {
      id: story.id,
      title,
      subtitle,
      slides,
      preview_image,
      category_id,
      project_id: project_id || null,
      published_at: published_at_raw.format(),
      status: nextstatus || story.status || "draft",
    };

    // Update article
    const _res = await API.Stories.update(payload);

    setSaving(false);

    if (_res.error || _res instanceof Error) {
      return message.error("Error saving stories, try again later", 1);
    }

    return message.success("Stories saved successfully", 1, () =>
      document.location.reload()
    );
  };

  return (
    <Layouts.AdminLayout title="Edit Story" loading={loading}>
      <Offline>
        <div className="offline-msgbox">
          <Alert
            className="msg"
            type="error"
            icon={<LoadingOutlined />}
            banner
            message="No Internet Connection"
            description="You are offline, please check your internet connection before saving anything."
          />
        </div>
      </Offline>
      <Form
        story={story}
        categories={categories}
        projects={projects}
        preview={preview}
        setPreview={setPreview}
        handleSave={handleSave}
        saving={saving}
        setStory={setStory}
      />
    </Layouts.AdminLayout>
  );
}
