import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Loading } from "@components";
import * as API from "@api";

export default function Logout() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function doLogout() {
      await Promise.all([API.Auth.logout()]);
      setLoading(false);
    }

    doLogout();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return <Navigate to="/login" />;
}
