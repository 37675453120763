import { Table, Button, Tag, message } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import * as API from "@api";
import { useEffect, useState } from "react";

export default function DataTable(params) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  useEffect(() => {
    onTableChange();
  }, []);

  async function onTableChange(p = pagination) {
    setLoading(true);
    const res = await API.Tags.getOne({
      id: params.tag.id,
      limit: p.pageSize,
      offset: p.current * p.pageSize - p.pageSize,
    });

    if (!res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setData(res.data.articles);
    setPagination({ ...p, total: res.data.count });
    setLoading(false);
  }

  const getStatusTag = (status) => {
    switch (status) {
      case "draft":
        return <Tag>Draft</Tag>;
      case "in_review":
        return <Tag color="orange">In Review</Tag>;
      case "planned_publish":
        return <Tag color="blue">Planned Publish</Tag>;
      case "published":
        return <Tag color="green">Published</Tag>;
      case "unpublished":
        return <Tag color="red">Unpublished</Tag>;
      case "removed":
        return <Tag color="red">Archived</Tag>;

      default:
        break;
    }
  };

  return (
    <Table
      onChange={onTableChange}
      pagination={pagination}
      dataSource={data}
      loading={loading}
      columns={[
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
          align: "left",
          width: 500,
          render: (data, all) => (
            <div>
              <h4 className="block m-0 text-base">{data}</h4>
              <p className="block m-0 mb-2 text-sm text-slate-500">
                {all.subtitle}
              </p>
            </div>
          ),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          align: "center",
          render: getStatusTag,
        },
        {
          dataIndex: "actions",
          key: "actions",
          align: "right",
          render: (_, all) => (
            <Link to={`/articles/edit/${all.id}`}>
              <Button type="primary">View Article</Button>
            </Link>
          ),
        },
      ]}
    />
  );
}
